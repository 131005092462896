.products-single-main .slider-small-img img {
  width: 100%;
}
.slider-small-img .card-type-one.selected {
  background-color: var(--card-type-one-color);
}
.slider-small-img {
  max-height: 30vw;
  overflow-y: scroll;
}
.counter {
  border: 2px solid black;
  border-radius: 10px;
}
.err-msg {
  color: red;
  display: none;
  font-size: 11px;
}
.counter > .col-4:not(:last-child) {
  border-right: 1px solid var(--span-main-color);
}
.icon-svg {
  height: 25px;
  width: 25px;
}
.products-single-main .favicon {
  border: 1px solid black;
  border-radius: 10px;
  background: white;
}
.products-single-main .products-inner-desc p {
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.preferred img {
  width: 70%;
}
.pages-scroll {
  overflow-y: scroll;
}
.products-single-main .card-type-two svg {
  font-size: 1.8rem;
  background-color: var(--card-type-one-color);
  padding: 0.6vh;
  border-radius: 50%;
}
.products-single-main .preferred-inner {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 1rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

.products-single-main .preferred-inner::-webkit-scrollbar {
  height: 6px;
}

.products-single-main .preferred-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.products-single-main .preferred-inner::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.products-single-main .preferred-inner > div {
  flex: 0 0 auto;
}
@media screen and (max-width: 1023px) {
  .products-single-main .slider-small-img {
    max-height: 70vw;
  }
}
.products-single-main .slider-small-img {
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.products-single-main .col-10 img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  background-color: white;
}

.slider-small-img img {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  background-color: white;
}

@media screen and (max-width: 767px) {
  .products-single-main .col-10 img {
    max-height: 300px;
  }

  .slider-small-img img {
    max-height: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .products-single-main .col-10 img {
    max-height: 350px;
  }
}
