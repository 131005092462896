@import url(../../color.css);
@font-face {
  font-family: 'MyCustomFont';
  src: url('../Poppins/Poppins-Medium.ttf') format('truetype');

}
.close-button {
  margin: 10px 0vh !important;
  z-index: 9;
}
.no-results-message {
  border-radius: var(--main-radius);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: auto !important;
}
.search-lg-main {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  z-index: 9999999;
  width: 222px;
  border-radius: 0.5rem;
}
.main-box-shadow:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
}
.close-button-mobile {
  display: none;
}
@media (min-width: 666px) {
  .nav-dropdown-menu-main .menu-link {
    padding: 0.2em 0.8em;
    cursor: pointer;
    font-weight: 500;
    font-family: 'MyCustomFont', sans-serif;

  }

  .nav-dropdown-menu,
  nav li:hover > input[type="checkbox"] + .nav-dropdown-menu-submenu {
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .nav-dropdown-menu-main .nav-dropdown-menu-title-link:hover {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .header-shadow {
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 20px 20px -20px; */
    border-bottom: 1px solid black;
    padding: 0.8vh 0vh;
  }
  .nav-dropdown-menu-body ul,
  .nav-dropdown-menu-title-link,
  .nav-dropdown-menu-toggle,
  nav .nav-dropdown-menu-submenu {
    border-radius: 0.5rem;

    padding: 0;
  }
  .nav-dropdown-menu-dropicon-right {
    display: inline-block;
    font-size: 1em;
    padding: 0.8em;
    text-align: center;
    text-shadow: 0 0 0 transparent;
  }
  .nav-dropdown-menu-dropicon-right,
  .nav-dropdown-menu-dropicon-bottom {
    border-radius: 0.4rem;
    padding: 0.3em 0.1em;
    position: absolute;
    right: 0vh;
    top: 0;
  }
  .nav-dropdown-menu-main {
    margin: 0;
  }
  .nav-dropdown-menu-main ul {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  }
  .nav-dropdown-menu-submenu {
    margin: 0 20px;
  }
  .nav-dropdown-menu-submenu li {
    border-radius: 0.4rem;
    width: max-content;
  }
  .nav-dropdown-menu-submenu,
  .nav-dropdown-menu-toggle-link,
  nav input[type="checkbox"],
  .nav-dropdown-menu-submenu .nav-dropdown-menu-dropicon-bottom {
    display: none;
  }

  .nav-dropdown-menu-title-link .menu-link::after {
    right: 50%;
    transform: translateX(50%);
  }
  .nav-dropdown-menu-title-link .menu-link::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .nav-dropdown-menu-title-link .menu-link::before,
  .nav-dropdown-menu-title-link .menu-link::after {
    background-color: #f1c40f;
    bottom: 0;
    content: "";
    height: 3px;
    position: absolute;
    transition: width 0.2s ease-out;
    width: 0;
  }
 
  .nav-dropdown-menu-title-link .menu-link:hover::before,
  .nav-dropdown-menu-title-link .menu-link:hover::after {
    background-color: var(--color-three);
    transition-duration: 0.3s;
    width: 80%;
  }

  .nav-dropdown-menu-toggle:checked + .nav-dropdown-menu,
  nav li,
  .nav-dropdown-menu-toggle,
  nav .menu-link,
  nav input[type="checkbox"]:checked + .nav-dropdown-menu-submenu {
    display: block;
  }

  nav .nav-dropdown-menu-submenu {
    background-color: white;

    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    margin: 0;
    position: absolute;
    top: 100%;
    z-index: 999999999;
  }
  nav .nav-dropdown-menu-submenu .nav-dropdown-menu-submenu {
    left: 100%;
    right: auto;
    top: 0;
  }
  nav .nav-dropdown-menu-submenu,
  nav input[type="checkbox"]:checked + .nav-dropdown-menu-submenu,
  .nav-dropdown-menu-toggle {
    display: none;
  }
  nav i {
    padding: 5px;
  }
  nav li {
    float: left;
    min-width: 179px;
    position: relative;
  }
}

.header-main {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 9;
  top: 0;
}

.header-main li {
  line-height: 35px;
  list-style: none;
}

.header-main .badge {
  cursor: pointer;
}
#search-bar {
  right: -1vh;
  width: 205px;
  opacity: 1;
  transition: width 0.3s ease;
  z-index: 9;
  border: none !important;
  outline: none !important;
  background-color: transparent;
  cursor: pointer;
}

.header-main .header-title:hover::after {
  width: 100%;
}

.header-main .search-bar-main {
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.nav-dropdown-menu-dropicon-bottom svg {
  font-size: 1.3rem;
  font-weight: 650;
}
.nav-dropdown-menu-dropicon-right svg {
  font-size: 1.3rem;
}
.search-text h6 {
  font-size: 11px;
}
.search-text span {
  font-size: 11px;
}
.search-bar-main {
  width: inherit;

  overflow-x: scroll;
  position: fixed;
}

@media screen and (max-width:1436px) {
  .header-main {
    display: none !important;
  }
}
@media screen and (max-width: 1050px) {
  .search-bar-main {
    max-height: 50vh;

    overflow-y: scroll;
    position: fixed;
    border-radius: 0rem;
  }

  #search-bar {
    z-index: 99999;
    background-color: white;
    width: 775px;
  }
  .search-bar-main {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .close-button-mobile {
    display: block;
    right: 1vh;
    position: fixed;
  }
}
@media screen and (max-width: 1436px) {
  .close-button-mobile {
    display: block;
    right: 1vh;
    position: fixed;
  }
}
@media screen and (max-width: 768px) {
  .search-lg-main {
    height: 80vh;
    width: 100%;
    border-radius: 0;
  }
  .close-button-mobile {
    display: block;
    right: 1vh;
    position: fixed;
  }
  #search-bar {
    width: 300px;
  }
  .card-type-search img {
    width: 70%;
  }
}

@media screen and (max-width: 1100px) {
  .search-lg-main {
    height: 90vh;
    width: 100%;
    border-radius: 0;
  }
  .card-type-search img {
    width: 70%;
  }
  .close-button-mobile {
    display: block;
    right: 1vh;
    position: fixed;
  }
}

.header-end-section a::before,
.header-end-section a::after {
  background-color: transparent !important;
  bottom: 0;
  content: "";
  height: 3px;
  position: absolute;
  transition: width 0.2s ease-out;
  width: 0;
}
.border-search {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
