.products-main .title {
  font-size: 2.2rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.products-main li {
  list-style: none;
  cursor: pointer;
}
.products-main ul {
  padding: 0;
  margin: 0;
  line-height: 28px;
}
.selected-category {
  color: #a13333;
}
.page-title {
  font-size: 2rem;
}
.products-main .navbar-nav svg {
  font-size: 1.3rem;
}
.products-main .active {
  color: #a13333;
  font-weight: 800;
}
.categories-large ul li {
  cursor: pointer;
}
.products-main .products-img img {
  width: 100%;
}
.products-main .filter-section p {
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.products-main .filter-section {
  position: absolute;
  background: white;
  z-index: 3;
  padding: 1rem 1rem 1rem 1rem;
}
.products-main .title-main {
  width: auto;
}
.fade-top {
  animation: fade-top 0.5s ease;
}

@keyframes fade-top {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.products-main .categories-large {
  display: block;
}
.transitionable-column {
  transition: width 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  .products-main .categories-large {
    display: none;
  }
  .products-single-main .favicon {
    padding: 0.5vh;
  }
  .products-img {
    height: 150px !important;
  }
  .page-title {
    font-size: 1.3rem;
  }
  .mobil-filter-size {
    width: 60vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .products-single-main .favicon {
    padding: 0.5vh;
  }
  .products-img {
    height: 205px !important;
  }
}
@media screen and (min-width: 2000px) {
  .products-img {
    height: 562px !important;
  }
}

.products-main .card-type-one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  background: white;
  border-radius: var(--main-radius);
  transition: transform 0.3s ease;
}

.products-main .card-type-one:hover {
  transform: translateY(-5px);
}

.products-main .products-img {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
}

.products-main .products-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.products-main .title-main {
  width: 100%;
  text-align: center;
  margin: 0.5rem 0;
  font-size: 1rem;
  min-height: 2.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.products-main .card-type-one p {
  margin: 0.5rem 0;
  text-align: center;
}

.products-main .card-type-one h6 {
  margin: 0.5rem 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .products-main .products-img {
    height: 200px;
  }

  .products-main .title-main {
    font-size: 0.9rem;
    min-height: 2.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .products-main .products-img {
    height: 250px;
  }
}

.bestseller-main .card-type-one {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  background: white;
  border-radius: var(--main-radius);
  transition: transform 0.3s ease;
}

.bestseller-main .products-img {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
}

.bestseller-main .products-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: white;
}

@media screen and (max-width: 767px) {
  .bestseller-main .products-img {
    height: 300px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bestseller-main .products-img {
    height: 350px;
  }
}
