.check-out-main input {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black;
}
.check-out-main input:focus-visible {
  outline: black !important;
}
.check-out-main textarea {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black;
}
.check-out-main .check-out-login-title a {
  border-bottom: 1px solid black;
}
.check-out-main select {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black;
}
.input-with-icon {
  position: relative;
}
.check-out-main .counter {
  border: 1px solid var(--span-main-color);
}
.input-with-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.check-out-main .accordion-button:not(.collapsed) {
  background-color: black !important;
  color: white !important;
}
.check-out-main .accordion-button:focus {
  box-shadow: none !important;
}
.check-out-main .accordion-button {
  background-color: var(--card-type-one-color) !important;
  border: 1px solid black;
  margin-bottom: 2vh;
  border-radius: 10px !important;
}
.havale-border {
  border: 1px solid var(--span-main-color);
  padding: 1vh;
  border-radius: var(--main-radius);
}
.check-out-main .accordion {
  --bs-accordion-border-color: none !important;
}
.check-out-main .accordion-body {
  padding: 1vh 0vh;
}
.check-out-main .check-out-img {
  width: 100%;
}
.check-out-main .orders-all {
  border-bottom: 1px solid var(--color-three);
}
.check-out-main .input-container {
  position: relative;
  display: inline-block;
  width: max-content;
}
.check-out-main .apply-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.checkbox-inner {
  background-color: var(--card-type-one-color);
  padding: 2vh 4vh;
  border-radius: var(--main-radius);
}
@media screen and (min-width: 768px) and (max-width: 1540px) {
  .checkbox-inner {
    padding: 1vh 2vh;
  }
}
