.home-section-two img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
.image-with-text {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
}

.centered-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  top: 80%;
  left: 10%;
  transform: translate(-10%, -80%);
}
