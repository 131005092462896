.user-account-main .account-option p {
  margin: 0;
  padding: 0;
  line-height: 35px;
}
.user-account-main .account-main {
  border-right: 2px solid black;
}
.user-account-main input {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black !important ;
}
.user-account-main .user-account-inner-title {
  border-bottom: 2px solid black;
}
.user-account-main .addres-main .addres-add-icon svg {
  font-size: 3rem;
}
.card-type-one .fav-not svg {
  background-color: gray !important;
  color: white;
  padding: 0;
  cursor: pointer;
}
.adres-edit input {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black !important ;

}
select{
  background-color: white !important;
  -webkit-appearance: none;
  outline: none;

}
.adres-edit select {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black;
}
.user-account-main .account-option p {
  cursor: pointer;
}

.user-account-main .card-type-two svg {
  font-size: 1.8rem;
  padding: 0.6vh;
  border-radius: 50%;
}
