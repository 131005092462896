.cart-main .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: end;
  align-items: center;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s 0.5s;
}
.cart-main .delete-button {
  padding: 0vh 2vh;
}
.cart-main .cart-inner {
  overflow-y: scroll;
  height: 68%;
  cursor: pointer;
  width: 100% !important;
}
.cart-main .cart-inner {
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.cart-main .modal-content {
  background-color: white;
  padding: 5px 20px;
  border-radius: 5px;
  width: 35%;
  height: 100%;
  justify-content: space-around;
  opacity: 1;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(0px);
}

.cart-main .modal-overlay.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 0s;
}

.cart-main .modal-content.closing {
  opacity: 0;
  transform: translateX(50px);
}
.cart-main .shopping-border {
  border-bottom: 1px solid black;
}
.cart-main .shopping-border svg {
  font-size: 1.7rem;
}
.cart-main .total-top {
  border-top: 1px solid black;
}
@media screen and (max-width: 768px) {
  .cart-main .modal-content {
    width: 87%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .cart-main .modal-content {
    width: 55%;
  }
  .cart-main .cart-inner {
   height: 80%;
  }
}
