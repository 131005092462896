.contact-page input,
.contact-page textarea {
  border-radius: 12px;
  border: 1px solid black;
  padding: 1vh;
}
.contact-page label {
  font-size: 12px;
}
.contact-page p {
  margin: 0;
  padding: 0;
}
