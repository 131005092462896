.card-type-one {
  border: 2px solid var(--card-type-one-color);
  border-radius: var(--main-radius);
  background: white;
}
.card-type-search {
  border: 2px solid var(--card-type-one-color);
  border-radius: var(--main-radius);
}
.card-type-search span{
  color: var(--span-main-color);
  font-size: 10px;
}
.card-type-search img{
  width: 100%;
}
.card-type-search h6 {
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.card-type-search {
  border-radius: var(--main-radius);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.card-type-one span {
  color: var(--span-main-color);
}
.card-type-two {
  border: 1px solid var(--card-type-one-color);
  border-radius: var(--main-radius);
  background-color: white;
}
.card-type-two span {
  color: var(--span-main-color);
}
.card-type-two.selected {
    background-color: var(--card-type-one-color);
  }

  .card-type-one.selected {
    background-color: var(--card-type-one-color);
  }


.card-type-one svg {
  font-size: 2rem;
  padding: 0.4vh;
}
.card-type-one .heart-icon {
  cursor: pointer;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.card-type-one:hover .heart-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.card-type-one img {
  transition: transform 0.3s ease;
  width: 100%;
}
.card-type-one:hover img {
  transform: scale(0.8) translateY(-30px);
  transition: transform 0.3s ease;
  -webkit-transform: scale(0.8) translateY(-30px);
  -moz-transform: scale(0.8) translateY(-30px);
  -ms-transform: scale(0.8) translateY(-30px);
  -o-transform: scale(0.8) translateY(-30px);
}
.card-type-one h6,
.card-type-one span,
.card-type-one p,
.card-type-one h2 {
  transition: transform 0.3s ease !important;
  -webkit-transition: transform 0.3s ease !important;
  -moz-transition: transform 0.3s ease !important;
  -ms-transition: transform 0.3s ease !important;
  -o-transition: transform 0.3s ease !important;
}
.card-type-one:hover h6,
.card-type-one:hover span,
.card-type-one:hover p ,
.card-type-one:hover h2{
  transform: translateY(-30px);
  transition: transform 0.3s ease;
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
}
