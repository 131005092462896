
:root {
    --card-type-one-color: rgb(229,229,229);
}

:root {
    --span-main-color: #09090991; 
}
:root {
    --color-three: #C4E5D7; 
}
