.whatsapp-main {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  left: 20px;
}
.whatsapp-main svg {
  fill: #25d366;
  font-size: 3rem;
}
@media screen and (max-width: 767px) {
  .whatsapp-main svg {
    font-size: 2.2rem;
  }
  .whatsapp-main {
    position: fixed;
    z-index: 999;
    bottom: 3vh;
  }
}
