/* BASE STYLES (ALL SCREEN SIZES) */
.home-slider-main {
  position: relative;
  height: 100%;
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
}

.home-slider-main .swiper {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 0;
  background-color: #fff;
}

.home-slider-main .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.home-slider-main .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.home-slider-main .slide-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-slider-main .slide-text {
  position: absolute;
  z-index: 5;
}

/* Button Animations */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  } /* Başlangıçta görünmez */
  10% {
    opacity: 0.6;
  } /* 0.5s sonra görünür olmaya başlar */
  20% {
    opacity: 0.9;
  } /* 1s sonra daha belirgin */
  50% {
    opacity: 1;
  } /* 2.5s boyunca tam görünür kalır */
  70% {
    opacity: 0.8;
  } /* 3.5s civarında solmaya başlar */
  85% {
    opacity: 0.4;
  } /* 4.25s civarında daha belirgin solma */
  100% {
    opacity: 0;
  } /* 5s sonunda tamamen kaybolur */
}

.animate-button {
  animation: fadeInOut 5s infinite !important;
}

/* Navigation arrows and pagination */
.home-slider-main .swiper-button-next:after,
.home-slider-main .swiper-button-prev:after {
  color: white !important;
  font-size: 35px !important;
}

.home-slider-main .swiper-pagination {
  bottom: 0 !important;
  padding-bottom: 2px;
}

.home-slider-main .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
}

.home-slider-main .swiper-pagination-bullet-active {
  background: white !important;
}

/* MOBILE STYLES (under 768px) */
@media (max-width: 767px) {
  /* Üstten margin'i artır */
  .home-slider-main .swiper {
    margin-top: 70px;
    margin-bottom: 0;
  }

  /* Slider container for mobile */
  .home-slider-main .slide-container {
    height: 35vh;
    min-height: 200px;
    max-height: 280px;
    padding: 0;
    margin: 0;
  }

  .home-slider-main .slide-container img {
    object-fit: contain;
    max-height: 90%;
    margin: 0;
    padding: 0;
  }

  /* Mobile button positioning */
  .home-slider-main .slide-text {
    position: absolute;
    bottom: 35%;
    left: 10%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    transform: none;
    padding: 0;
  }

  /* Hide navigation arrows on mobile */
  .home-slider-main .swiper-button-next,
  .home-slider-main .swiper-button-prev {
    display: none;
  }

  .home-slider-main .swiper-pagination {
    bottom: -5px !important;
    padding-bottom: 0;
  }

  /* Mobile button container */
  .mobile-button-container {
    position: relative;
    z-index: 5;
    margin-bottom: 0;
  }

  /* Mobile button style overrides - slider specific */
  .home-slider-main .mobile-button-type {
    width: 75px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    color: rgba(255, 255, 255, 1);
    opacity: 0;
    pointer-events: auto;
    animation: fadeInOut 5s infinite;
    font-size: 14px;
  }

  /* Mobile button hover/active state */
  .home-slider-main .mobile-button-type:hover,
  .home-slider-main .mobile-button-type:active {
    opacity: 0.95;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
    transform: scale(1.01) translateY(2px);
    color: rgba(255, 255, 255, 1);
    animation: none;
  }
}

/* YATAY TELEFON (LANDSCAPE) STYLES */
@media only screen and (max-width: 967px) and (orientation: landscape) {
  /* Slider container için ayarlar */

  /* Buton stilleri */
  .home-slider-main .mobile-button-type {
    margin-top: 40px;
    width: 65px !important;
    height: 20px !important;
    font-size: 12px !important;
    border-radius: 3px !important;
    opacity: 0 !important;
    pointer-events: auto !important;
    animation: fadeInOut 5s forwards !important;
  }
}

/* TABLET STYLES (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Üstten margin'i artır */
  .home-slider-main .swiper {
    margin-top: 55px;
    margin-bottom: 0;
  }

  /* Slider container for tablet */
  .home-slider-main .slide-container {
    height: 40vh;
    min-height: 250px;
    max-height: 350px;
    padding: 0;
    margin: 0;
  }

  .home-slider-main .slide-container img {
    object-fit: contain;
    max-height: 90%;
    margin: 0;
    padding: 0;
  }

  /* Tablet button positioning */
  .home-slider-main .slide-text {
    position: absolute;
    bottom: 35%;
    left: 12%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    transform: none;
    padding: 0;
  }

  /* Mobile button container for tablet */
  .mobile-button-container {
    position: relative;
    z-index: 10;
    margin-bottom: 10px;
  }

  /* Mobile button style adjustments for tablet - now matching mobile */
  .home-slider-main .mobile-button-type {
    width: 75px;
    height: 25px;
    padding: 0;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    color: rgba(255, 255, 255, 1);
    opacity: 0;
    pointer-events: auto;
    animation: fadeInOut 5s infinite;
    font-size: 14px;
  }

  /* Mobile button hover/active state for tablet - now matching mobile */
  .home-slider-main .mobile-button-type:hover,
  .home-slider-main .mobile-button-type:active {
    opacity: 0.95;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
    transform: scale(1.01) translateY(2px);
    color: rgba(255, 255, 255, 1);
    animation: none;
  }
}

/* 1024x1366 STYLES (iPad Pro ve benzeri büyük tabletler) */
@media (min-width: 1024px) and (max-width: 1366px) {
  /* Üstten margin değeri */
  .home-slider-main .swiper {
    margin-top: 65px;
  }

  /* Slider container */
  .home-slider-main .slide-container {
    height: 50vh;
    min-height: 350px;
    max-height: 550px;
  }

  /* Görsel özellikleri */
  .home-slider-main .slide-container img {
    object-fit: contain;
    max-height: 92%;
  }

  /* Button positioning - tablet ile benzer */
  .home-slider-main .slide-text {
    position: absolute;
    bottom: 35%;
    left: 12%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    transform: none;
  }

  /* Button container ayarları */
  .mobile-button-container {
    position: relative;
    z-index: 10;
    margin-bottom: 10px;
  }

  /* Desktop button container gizlenir */
  .desktop-button-container {
    display: none;
  }

  /* Button style - tablet ile birebir aynı */
  .home-slider-main .mobile-button-type {
    width: 75px;
    height: 25px;
    padding: 0;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    color: rgba(255, 255, 255, 1);
    opacity: 0;
    pointer-events: auto;
    animation: fadeInOut 5s infinite;
    font-size: 14px;
  }

  /* Button hover/active state - tablet ile birebir aynı */
  .home-slider-main .mobile-button-type:hover,
  .home-slider-main .mobile-button-type:active {
    opacity: 0.95;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
    transform: scale(1.01) translateY(2px);
    color: rgba(255, 255, 255, 1);
    animation: none;
  }
}

/* DESKTOP STYLES (1367px and above) */
@media (min-width: 1367px) {
  /* Ana ölçekler */
  .home-slider-main .swiper {
    margin-top: 80px;
  }

  /* Slider container for desktop */
  .home-slider-main .slide-container {
    height: 70vh;
  }

  /* Desktop button positioning */
  .home-slider-main .slide-text {
    top: 68%;
    left: 14%;
    transform: translateY(-50%);
  }

  /* Desktop button container */
  .desktop-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 120px;
  }
}
