.orders-main .react-tabs__tab--selected {
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 12px;
}
.orders-main .react-tabs__tab {
  border: 1px solid black;
  border-radius: 12px;
  margin-right: 1vh;
}
.react-tabs__tab-list {
  border-bottom: none !important;
}
.orders-main hr {
  border-top: 2px solid black;
  opacity: 1;
}
.orders-main .card-type-two p {
  padding: 0;
  font-size: 14px;
  margin: 0;
}
.orders-main img {
  height: 200px;
  border-radius: var(--main-radius);
  transition: height 0.5s ease;
}

.orders-main .takip_no {
  color: gray;
  font-size: 13px;
}
.orders-main .card-type-two.expanded img {
  height: 350px;
  background-color: white;
  border-radius: 20px;
}
.orders-main .card-type-two.red-background {
  background-color: var(--color-three);
}

.orders-main h6 {
  font-size: 15px;
}

.orders-main .order-tracking {
  text-align: center;
  width: 16.6%;
  position: relative;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.orders-main .order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 0px solid #afafaf;
  background-color: green;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.orders-main .order-tracking.completed .is-complete {
  border-color: black;
  border-width: 0px;
  background-color: black;
}

.orders-main .order-tracking p {
  width: min-content;
  color: #a4a4a4;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 0;
  line-height: 18px;
}
.orders-main .order-tracking p span {
  font-size: 14px;
}
.orders-main .order-tracking.completed p {
  color: #000;
}
.orders-main .order-tracking::before {
  content: "";
  display: block;
  height: 2px;
  width: calc(100% - 20px);
  background-color: black;
  top: 10px;
  position: absolute;
  left: calc(-50% + 10px);
  z-index: 0;
}
.orders-main .order-tracking:first-child:before {
  display: none;
}
.orders-main .order-tracking.completed:before {
  background-color: black;
}

.orders-main svg {
  font-size: 1.5rem;
  margin: 1vh;
}

@media screen and (max-width: 768px) {
  .orders-main img {
    height: 160px;
  }
  .orders-main .card-type-two.expanded img {
    height: 175px;
  }
  .orders-main .react-tabs__tab {
    margin-top: 0.5rem;
  }
  .orders-main .order-tracking {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .products-single-main .favicon {
    padding: 0.5vh;
  }

  .orders-main img {
    height: 340px;
  }
  .orders-main .card-type-two.expanded img {
    height: 385px;
  }
  .orders-main .order-tracking {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .orders-main img {
    height: 470px;
  }
  .orders-main .card-type-two.expanded img {
    height: 570px;
  }
  .orders-main .order-tracking {
    display: none;
  }
}
