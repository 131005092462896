.custom-dropdown {
    position: relative;
    display: inline-block
}

.custom-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between
}
.border-lang{
    border: 1px solid #dee2e6;
}
.custom-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    float: left;
    min-width: 93px;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175)
}

.dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:hover {
    background-color: #f8f9fa;
    cursor: pointer
}
@media screen and (max-width: 768px) {
.border-lang{
    border: none;
}
.custom-dropdown-menu {
    min-width: 65px;
  }
   }