.header-section {
  background-color: black;
  color: white;
  position: relative;
  z-index: 999;
}
.header-section-none {
  display: none; 
}

.header-section-none.visible {
  display: block; 
}

.header-section svg {
  margin: 0vh 0.3vh;
}
.header-section-two svg {
  margin: 0vh 0.3vh;
}
.header-section-one-main a {
  color: white !important;
}
.header-section-two select {
  border: none !important;
  outline: none !important;
}
.header-section h6 {
  margin: 0;
  padding: 0;
}
.header-section-close {
  position: absolute;
  right: 2.8%;
  cursor: pointer;
}
.marque-text {
  z-index: -2;
}

.header-section-two h6 {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .header-section h6 {
    margin: 0;
    padding: 0;
    font-size: 11px;
  }

  .header-section-two h6 {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }
  .header-section-two svg {
    margin: 0vh 0.2vh;
    font-size: 0.8rem;
  }
  .header-section-none {
    border-bottom: 1px solid black;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .header-section h6 {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
  .header-section-none {
    border-bottom: 1px solid black;
  }
}
@media screen and (max-width: 1220px) {
  .header-item-none {
    display: none !important ;
    
  }
  .mobil-lang{
    display: block !important;
  }
}
.header-item-none {
  display: block;
  
}
.mobil-lang{
  display: none;
}