@import url(./color.css);
@import url(./button.css);
@import url(./card.css);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  /* background-image: url(./assets/img/footer.svg); */
  font-family: "Montserrat", sans-serif;
}
.header-logo{
  height: 6vh;
}
:root {
  --main-radius: 10px;
}
input:-internal-autofill-selected {
background-color: white !important;
}
a {
  color: black !important;
  text-decoration: none !important ;
}
.main-radius {
  border-radius: var(--main-radius);
}
.stores-main img {
  width: -webkit-fill-available;
}
.svg-icon {
  height: 15px;
}
.infinite-scroll-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.main-space {
  margin-top: 23vh;
  padding: 0vh 12vh !important;
}
.blog-main-img img{
  max-width: -webkit-fill-available;
}
.cursor-pointer {
  cursor: pointer;
}
.status-img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  width: 800px;
  height: 500px;
  object-fit: cover;
}
.text-gray {
  color: var(--span-main-color);
}
.loader-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seo-text {
  display: none;
}
.price-strike {
  text-decoration: line-through;
  font-size: 1rem; /* İsterseniz font boyutunu ayarlayın */
}

.price-zero {
  display: block; /* Yeni satıra geçmesi için block olarak ayarlayın */
  font-size: 0.9rem; /* İsterseniz daha küçük bir font boyutu belirleyin */
  color: red !important; /* Renk ayarı, tercihinize bağlı */
}
.bg-success-campaign{
  background:black;
  background-color: black;
  color: white;
}
.styles-module_tooltip__mnnfp {
  width: 50% !important;
  z-index: 9999;
}
.bg-success-campaign:hover{
  background:black;
  background-color: black;

}
.header-top {
  position: relative;
  margin-top: 15vh;
}
.counter-inner {
  cursor: pointer;
}

.bg-subs {
background-image: url("../src/assets//img/banner_001.webp"); 
background-size: cover;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: var(--main-radius);
}
.bg-blog {
  background-image: url("../src/assets/img/footer.svg");
  background-size: cover;
}

.heart-icon {
  cursor: pointer;
  color: #ce999a !important;
}

.title-main {
  width: auto;
  font-size: 1rem;
}
.status-main {
  font-size: 4rem;
}

@media screen and (max-width: 1240px) {
  .title-main {
    width: auto;
    text-align: start;
  }
}
@media screen and (max-width: 768px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 20px !important;
  }
  .main-space {
    padding: 0vh 3vh !important;
    margin-top: 17vh;
  }
  .svg-icon {
    height: 14px;
  }

}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .main-space {
    padding: 0vh 5vh !important;
    margin-top: 16vh;
  }

  .title-main {
    width: auto;
  }
}
@media screen and (min-width: 1026px) and (max-width: 1370px) {
  .main-space {
    padding: 0vh 5vh !important;
    margin-top: 19vh;
  }
}


.loader-div{
  width: 100vw;
  height: 80vh;
}