.footer-main svg {
  font-size: 1.2rem;
  color: black;
}
.footer-main p {
  padding: 0;
  margin: 0;
}
.footer-main {
  padding: 5vh 0vh;
  background-image: url("../img/footer.svg");
  background-size: cover;
}
.footer-inner {
  border-top: 2px solid var(--card-type-one-color);
}
.footer-main .footer-inner .footer-madeby p {
  color: var(--span-main-color);
}
.footer-main .footer-inner .footer-madeby a {
  color: var(--span-main-color) !important;
}
.footer-main .footer-inner .madeby {
  border-bottom: 1px solid var(--span-main-color);
}
.footer-head {
  font-size: large;
}
@media screen and (max-width: 991px) {
  .footer-logo {
    display: flex;
    justify-content: center;
  }
  .footer-social {
    display: flex;
    justify-content: center;
  }
  .second-column .child {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.navbar-classs li {
  list-style: none;
}
@media screen and (max-width: 500px) {
  .footer-items-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-classs ul li {
    display: flex;
    align-items: center;
  }
}
