.card-type-coupon {
  border: 3px solid var(--card-type-one-color);
  border-radius: var(--main-radius);
  background-color: white;
}
.card-type-coupon p,
h1,
h2,
span,
h3 {
  cursor: pointer;
}
.card-type-coupon img {
  width: 150px;
  height: 150px;
}
.card-type-coupon span {
  color: var(--span-main-color);
}
.coupon-inner-bg {
  background-color: var(--card-type-one-color);
  border-radius: var(--main-radius);
}
