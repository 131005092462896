.login-main .login-inner {
  border-right: 2px solid black;
}
.login-main input {
  border-radius: 10px;
  padding: 1vh 2vh;
  border: 1px solid black !important ;
}
.login-main .login-inner span {
  color: var(--span-main-color);
  text-decoration: underline;
  font-size: 15px;
}
.login-main svg {
  font-size: 1.3rem;
}
.password-input {
  position: relative;
}
.PhoneInputCountry{
  display: none !important;
}
.password-toggle {
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-36%);
  cursor: pointer;
  color: gray;
 
}

@media screen and (min-width: 268px) and (max-width: 1025px) {
  .login-main .login-inner {
    border-right: none;
  }
}
