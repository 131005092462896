.bm-burger-button {
  display: none;
}
.bm-menu {
  background: transparent !important;
}
.bm-menu-wrap {
  background-image: url("../img/footer.svg");
  background-size: cover;
}
.arrow svg {
  font-size: 1.4rem;
}
.arrow {
  display: flex;
}
.bm-item h6 {
  margin: 0 0 2vh 0;
  padding: 0;
}
.mobil-menu-text svg {
  font-size: 0.8rem;
}
.mobile-filter-menu .bm-menu-wrap {
  left: 0;
  top: 0;
  width: max-content !important;
}
.mobile-filter-menu .bm-overlay {
  top: 0;
}
.mobil-menu-text p {
  font-size: 12px;
}
.bmBurgerButton {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}
.bm-item-list {
  display: flex;
  width: 100%;
}
.bmBurgerBars {
  background: #373a47;
}
.bmBurgerBarsHover {
  background: #a90000;
}
.bmCrossButton {
  height: 24px;
  width: 24px;
}
.bmCross {
  background: #bdc3c7;
}
.bmMenuWrap {
  position: fixed;
  height: 100%;
}
.bmMenu {
  background: #373a47;
  font-size: 1.15em;
}
.bmMorphShape {
  fill: #373a47;
}
.bmItemList {
  color: #b8b7ad;
}
.bmItem {
  display: inline-block;
}
.bmOverlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-cross-button {
  display: none !important;
}
.mobile-menu {
  display: none;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 9;
  top: 0;
}

.mb-ul ul {
  list-style-type: none;
}
.bm-item {
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.mobil-bottom-inner p {
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.mobile-menu .btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.mobil-border-bottom {
  border-bottom: 1px solid var(--span-main-color);
}
@media screen and (max-width: 1436px) {
  .mobile-menu {
    display: block;
  }
  .navbar-expand-lg .navbar-toggler {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 1125px) {
  .bm-menu-wrap {
    width: 400px !important;
  }
}

.side-menu-div a {
  margin-top: 4vh;
}
